import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Login`}</h4>
    <p>{`Click on the user icon at the top right of the screen and then on "Login"
in the dropdown menu.`}</p>
    <br />
    <p>{`Trailguide as a free service. We don't gather and sell the data from our users!
But read the `}<Link to="/legal/useragreement/" mdxType="Link">{`terms of use`}</Link>{` and accept
if you agree.`}</p>
    <Image src="features/login.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Facebook`}</h4>
    <p>{`To make it easy and fast for you, you don't need to create a separate
account for Trailguide.`}</p>
    <br />
    <p>{`You can simply use your Facebook login. No login details from Facebook
is transferred or seen by Trailguide! Read more about `}<a parentName="p" {...{
        "href": "/privacy.pdf"
      }}>{`our privacy policy`}</a>{`.`}</p>
    <Image src="features/loginfacebook.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/overlays" mdxType="Link">
  ← Overlays
    </Link>
    <Link className="float-right" to="/features/userpage" mdxType="Link">
  User page →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      